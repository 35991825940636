import ShareIcon from "../../assets/SVG/shareIcon.svg";
import DownloadIcon from "../../assets/SVG/downloadImageIcon.svg";
import TrafficLightAnimation from "../Animation";
import { useDispatch, useSelector } from "react-redux";
import { Key, useState } from "react";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import ApplicationLoader from "../../components/ApplicationLoader";
import { clearRemoteResources } from "../../redux/reducers/imageReducer";

const Download = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch(); 

  const handleDownload = async (imageUrl: RequestInfo | URL) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "image.jpg";
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading image: ", error);
    }
  };

  const handleShare = async (imageUrl: string) => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: "Image",
          text: "Check out this image!",
          url: imageUrl,
        });
      } else {
        console.log("Web Share API not supported on this browser.");
        // You can provide fallback behavior for older browsers here
      }
    } catch (error) {
      console.error("Error sharing image: ", error);
    }
  };
  const goToHome = () => {
    navigate("/home");
    dispatch(clearRemoteResources())
  };

  const remoteResourcesImages = useSelector(
    (rootState: RootState) => rootState.rootReducer.remoteResourcesImages
  );
  const [imageLoadedStates, setImageLoadedStates] = useState(Array(remoteResourcesImages.length).fill(false));

  const handleImageLoad = (index: number) => {
    setTimeout(() => {
      setImageLoadedStates(prevStates => prevStates.map((state, i) => i === index ? true : state));
    }, 3000);
  };

  return (
    <>
      {!remoteResourcesImages.length ? (
        <ApplicationLoader />
      ) : (
        <div className="flex flex-col items-center gap-10 overflow-y-scroll h-screen">
          {/* <BackBar /> */}
          <div className="flex flex-col gap-6 px-5 mt-16">
            {remoteResourcesImages.map(
              (image: string, index: Key | null | undefined) => (
                <div className="mb-2">
                  <TrafficLightAnimation>
                    <img src={image} alt="" className="" key={index} onLoad={() => handleImageLoad(index as number)} />
                  </TrafficLightAnimation>
                  {
                    imageLoadedStates[index as number] && <div className="flex justify-between mt-[-40px] px-5 relative z-10">
                    <img
                      src={ShareIcon}
                      alt=""
                      className="w-6 h-6"
                      onClick={() => handleShare(image)}
                    />
                    <img
                      src={DownloadIcon}
                      alt=""
                      className="w-6 h-6"
                      onClick={() => handleDownload(image)}
                    />
                  </div>
                  }
                  
                </div>
              )
            )}
          </div>
          <div
            className="flex py-1 px-10px justify-center items-center gap-10px rounded-35 bg-white mb-10"
            onClick={goToHome}
          >
            <p className="text-center text-RossoF1 font-Formular1R text-xs font-bold uppercase w-full">
              back to the homepage
            </p>
          </div>
        </div>
      )}
    </>
  );
};
export default Download;
