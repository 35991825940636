import { gsap } from "gsap";
import "./animation.css";
import { useLayoutEffect } from "react";

const TrafficLightAnimation = (props: any) => {
  let tl = gsap.timeline();

  useLayoutEffect(() => {
    tl.to(".semaforo:nth-of-type(1) .red", {
      duration: 0.5,
      background: "#FF0006",
      delay: 0.5,
    });

    tl.to(".semaforo:nth-of-type(2) .red", {
      duration: 0.15,
      background: "#FF0006",
      delay: 0,
    });

    tl.to(
      ".container",
      {
        duration: 0.5,
        backdropFilter: "blur(24px)",
      },
      "<"
    ); // parte quando parte l'animazione prima

    tl.to(".semaforo:nth-of-type(3) .red", {
      duration: 0.5,
      background: "#FF0006",
      delay: 0,
    });

    tl.to(
      ".container",
      {
        duration: 0.25,
        backdropFilter: "blur(12px)",
        delay: 0.25,
      },
      "<"
    );

    tl.to(".semaforo:nth-of-type(4) .red", {
      duration: 0.5,
      background: "#FF0006",
      delay: 0,
    });

    tl.to(
      ".container",
      {
        duration: 0.25,
        backdropFilter: "blur(8px)",
        delay: 0.25,
      },
      "<"
    ); 

    tl.to(".semaforo:nth-of-type(5) .red", {
      duration: 0.5,
      background: "#FF0006",
      delay: 0,
    });

    tl.to(
      ".semaforo:nth-of-type(1) .red, .semaforo:nth-of-type(2) .red, .semaforo:nth-of-type(3) .red, .semaforo:nth-of-type(4) .red, .semaforo:nth-of-type(5) .red",
      {
        duration: 0.2,
        background: "#323232",
      }
    );

    tl.to(
      ".container",
      {
        duration: 0.25,
        backdropFilter: "blur(0px)",
        delay: 0.25,
      },
      "<"
    );

    tl.to(".container", {
      duration: 0.25,
      opacity: 0,
    });
  });

  return (
    <>
      <div className="card">
        <div className="placeholder">{props.children}</div>
        <div className="container">
          <div className="container-semafori">
            <div className="line"></div>
            <ul className="semaforo">
              <li className=""></li>
              <li className=""></li>
              <li className="red"></li>
              <li className="red"></li>
            </ul>

            <ul className="semaforo">
              <li className=""></li>
              <li className=""></li>
              <li className="red"></li>
              <li className="red"></li>
            </ul>

            <ul className="semaforo">
              <li className=""></li>
              <li className=""></li>
              <li className="red"></li>
              <li className="red"></li>
            </ul>

            <ul className="semaforo">
              <li className=""></li>
              <li className=""></li>
              <li className="red"></li>
              <li className="red"></li>
            </ul>

            <ul className="semaforo">
              <li className=""></li>
              <li className=""></li>
              <li className="red"></li>
              <li className="red"></li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrafficLightAnimation;
