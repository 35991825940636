import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import SelectedDrivers from "../../components/Homepage/components/SelectedDrivers/SelectedDrivers";
import { useNavigate } from "react-router-dom";
import arrowBack from "../../assets/SVG/arrowBack.svg";
import {
  setActiveTabValue,
  setFirstVisitValue,
} from "../../redux/reducers/homeReducer";
import BackBar from "../../components/Shared/BackBar/BackBar";
import SecondaryButton from "../../components/Shared/Buttons/SecondaryButton/SecondaryButton";
import { useState } from "react";
import { setTotalAmmount } from "../../redux/reducers/userReducer";

const TotalAmount: React.FC<any> = () => {
  const selectedCharacters = useSelector(
    (rootState: RootState) => rootState.characters.selectedCharacters
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setTotalAmmount(selectedCharacters.length * 4));
    navigate("/user-form");
  };
  const handleBack = () => {
    // update the redux state values of first login and activeTab
    // this will take the user straight to the team selection
    dispatch(setFirstVisitValue(true));
    dispatch(setActiveTabValue("choose"));
    navigate("/home");
  };
  return (
    <>
      {/* <div className=" w-full p-4 bg-red-600">
        <img src={arrowBack} alt="Arrow Back" width={30} onClick={handleBack} />
      </div> */}
      <BackBar />
      <div className="flex flex-col">
        <div className="flex w-full px-6 py-4">
          <div className="w-327 text-white font-Formular1B text-3xl font-normal">
            YOUR CHOICES:
          </div>
        </div>
        <div
          className="flex flex-col gap-2 w-full pt-6 pb-6 pl-6"
          style={{
            background:
              "linear-gradient(180deg, rgba(85, 3, 0, 0.00) 0%, rgba(85, 3, 0, 0.70) 0.01%, rgba(85, 3, 0, 0.00) 100%)",
          }}
        >
          <div className="text-white font-[Titillium Web] text-base font-normal">
            You selected the following drivers:
          </div>
          <SelectedDrivers
            maxDrivers={30}
            selectedDrivers={selectedCharacters}
          />
        </div>
        <div
          className="flex w-full h-112 items-center justify-center px-7 pt-6 flex-col"
          // style={{
          //   borderTop: "1px solid #E10600",
          //   background:
          //     "linear-gradient(180deg, rgba(85, 3, 0, 0.00) 0%, rgba(85, 3, 0, 0.70) 0.01%, rgba(85, 3, 0, 0.00) 100%)",
          // }}
        >
          <div className="gap-7 inline-flex items-center flex-col p-10px justify-center mt-7 mb-7">
            <div className="font-Formular1R text-5xl text-white font-normal text-center">
              <span className="text-xl font-normal font-Formular1R text-center text-white">
                TOTAL SWAPPED PHOTOS:
              </span>
              <br /> {selectedCharacters.length * 8}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TotalAmount;
