import React from "react";
import { useDispatch } from "react-redux";
import { deleteSingleCharacter } from "../../../../redux/reducers/characterReducer";
import { useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { Teams } from "../../../../data/data";

interface SelectedDriversProps {
  maxDrivers: number;
  selectedDrivers: string[];
  borderColor?: string; // New prop for border color
}

const SelectedDrivers: React.FC<SelectedDriversProps> = ({
  maxDrivers,
  selectedDrivers,
  borderColor,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const deleteCharacter = (name: string) => {
    if (name !== "") {
      dispatch(deleteSingleCharacter(name));
    }
  };

  const determineIfEditable = () => {
    const pageUrl = location.pathname;
    if (pageUrl === "/home") {
      return false;
    } else {
      return true;
    }
  };
  const getTeamBg = (driverName: string): string | undefined => {
    const team = Teams.find((team) =>
      team.characters.some((character) => character.name === driverName)
    );

    return team ? team.color : undefined;
  };

  return (
    <div className="flex h-full overflow-x-auto">
      {Array.from({ length: maxDrivers }).map((_, index) => {
        let characterColor = getTeamBg(selectedDrivers[index]);
        return (
          <div
            key={index}
            className={twMerge(
              "flex items-center justify-center w-14 h-14 grow-0 shrink-0 bg-white border-2 border-solid rounded-lg text-center text-[8px] font-Formular1R mx-1 uppercase font-medium px-1 py-4",
              determineIfEditable() ? "pointer-events-none" : ""
            )}
            style={{
              borderColor: characterColor,
              color: characterColor,
            }}
            onClick={() => deleteCharacter(selectedDrivers[index] as string)}
          >
            {selectedDrivers[index]}
          </div>
        );
      })}
    </div>
  );
};

export default SelectedDrivers;
