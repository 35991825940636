import React, { useEffect, useState } from "react";
import { Wheel } from "react-custom-roulette";
import BackBar from "../../components/Shared/BackBar/BackBar";
import SecondaryButton from "../../components/Shared/Buttons/SecondaryButton/SecondaryButton";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../redux/store";
import ApplicationLoader from "../../components/ApplicationLoader";
import { decrementFreeSpinsLeft } from "../../redux/reducers/userReducer";
import UpgradeModal from "../../components/Homepage/components/UpgradeModal/UpgradeModal";
import { Teams } from "../../data/data";

interface WheelData {
  option?: string;
  style?: StyleType; // Optional
  optionSize?: number; // Optional
}

interface StyleType {
  backgroundColor?: string; // Optional
  textColor?: string; // Optional
  fontFamily?: string; // Optional
  fontSize?: number; // Optional
  fontWeight?: number | string; // Optional
  fontStyle?: string; // Optional
}

export const racingTeamSquadWheelOptions: WheelData[] = [
  {
    option: "Mercedes",
    style: { backgroundColor: "#00A19C", textColor: "white" },
  },
  { option: "BWT ALPINE", style: { backgroundColor: "#FC4BC7" } },
  {
    option: "Alfa Romeo",
    style: { backgroundColor: "#A50E2D", textColor: "white" },
  },
  {
    option: "Mclaren",
    style: { backgroundColor: "#FF8000", textColor: "white" },
  },
  { option: "Haas", style: { backgroundColor: "#FAFAFA" } },
  {
    option: "Aston Martin",
    style: { backgroundColor: "#005A50", textColor: "white" },
  },
  { option: "Scuderia Alphatauri", style: { backgroundColor: "#002940" } },
  {
    option: "William",
    style: { backgroundColor: "#00A2E0", textColor: "black" },
  },
  {
    option: "Red Bull",
    style: { backgroundColor: "#23326A", textColor: "white" },
  },
  {
    option: "Ferrari",
    style: { backgroundColor: "#E10600", textColor: "white" },
  }, //si
];

export const WheelRacingTeams: WheelData[] = Teams.map((team) => ({
  option: team.name,
  style: { backgroundColor: team.color, textColor: "white" },
}));

const WheelOfFortune: React.FC = () => {
  const [mustSpin, setMustSpin] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpgradeModalVisible, setIsUpgradeModalVisible] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("WheelRacingTeams", WheelRacingTeams);
  }, []);

  const wheelOfFortunePrizeNumber = useSelector(
    (rootState: RootState) => rootState.rootReducer.wheelOfFortunePrizeNumber
  );

  const freeSpinsLeft = useSelector(
    (rootState: RootState) => rootState.userReducer.freeSpinsLeft
  );

  const handleSpinClick = () => {
    if (!mustSpin) {
      // const newPrizeNumber: number = Math.floor(Math.random() * racingTeamSquadWheelOptions.length);
      // const preWinner = racingTeamSquadWheelOptions[newPrizeNumber]
      // console.log("Preselcted winner", preWinner)
      // setPrizeNumber(newPrizeNumber);
      if (freeSpinsLeft > 0) {
        setMustSpin(true);
        dispatch(decrementFreeSpinsLeft());
      } else {
        setIsUpgradeModalVisible(true);
      }
    }
  };

  const onStopSpinning = () => {
    setMustSpin(false);
    setTimeout(() => {
      setIsLoading(true);
    }, 1500);
    setTimeout(() => {
      navigate("/download");
    }, 3000);
  };

  return (
    <>
      {false ? (
        <ApplicationLoader />
      ) : (
        <>
          <BackBar />
          <div className="flex flex-col w-full h-full items-center">
            {isUpgradeModalVisible && (
              <UpgradeModal onFirstVisit={() => navigate("/home")} />
            )}
            {/* Titles & BTN*/}
            <h1 className="font-Formular1R text-white text-center text-4xl tracking-tight max-w-[317px] self-center mt-5 mb-5">
              Spin the Wheel
            </h1>
            <p className="text-white text-opacity-70 text-center text-lg leading-[120%] tracking-tight self-center mb-2">
              {freeSpinsLeft} spins left
            </p>
            <SecondaryButton
              onClick={handleSpinClick}
              enabled={true}
              text={"SPIN!"}
              width={"6/12"}
            />
            {/* WHEEL */}
            <div className="mt-10 font-Formular1R">
              <Wheel
                mustStartSpinning={mustSpin}
                prizeNumber={wheelOfFortunePrizeNumber}
                data={WheelRacingTeams}
                onStopSpinning={onStopSpinning}
                fontFamily={"Formular1-Regular"}
                outerBorderColor={"red"}
                outerBorderWidth={9}
                radiusLineColor={"none"}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default WheelOfFortune;
