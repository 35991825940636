import React from "react";

interface PrimaryButtonProps {
  enabled: boolean;
  text: string;
  hexTextColor?: string;
  fontSize?: string;
  width?: string;
  height?: string;
  onClick?(): any;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  enabled,
  text,
  hexTextColor,
  width,
  height,
  fontSize,
  onClick,
}) => {
  return (
    <button
      className={`w-${width ? width : "9/12"} h-${
        height ? height : "10"
      } rounded-full uppercase text-12 ${
        enabled ? "bg-red-600" : "bg-red-950 cursor-not-allowed"
      }`}
      disabled={!enabled}
      onClick={onClick}
    >
      <div
        className={`font-bold text-[${
          hexTextColor ? hexTextColor : "#ffffff"
        }] text-${fontSize ? fontSize : "base"}`}
      >
        {text}
      </div>
    </button>
  );
};

export default PrimaryButton;
