import { Teams } from "../../data/data";

export function findTeamAndId(driverSelected: string) {
    for (const team of Teams) {
      for (const character of team.characters) {
        if (character.name === driverSelected) {
          return { teamName: team.backendKey, characterId: character.id };
        } else {
          continue;
        }
      }
    }
  }