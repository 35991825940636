import React, { useMemo, useRef, useState } from "react";
import XIcon from "./../../../../assets/icons/x-icon.svg";
import WelcomePhoneScreen from "./../../../../assets/PNG/firstlogin-wizard-step0-3x.png";
import Step1PhoneScreen from "./../../../../assets/PNG/firstlogin-wizard-step1-3x.png";
import Step2PhoneScreen from "./../../../../assets/PNG/firstlogin-wizard-step2-3x.png";
import Step3PhoneScreen from "./../../../../assets/PNG/firstlogin-wizard-step3-3x.png";
import Step4PhoneScreen from "./../../../../assets/PNG/firstlogin-wizard-step4-3x.png";
import FirstLoginStepper from "../FirstLoginStepper/FirstLoginStepper";
import { useDispatch } from "react-redux";
import { setFirstVisitValue } from "../../../../redux/reducers/homeReducer";

interface StepConfiguration {
  title: string;
  stepNumber: number;
  stepDescription: string;
  photoResource: string;
}

const FirstLoginWizard: React.FC<any> = (props) => {
  const [step, setStep] = useState<number>(0);
  const dispatch = useDispatch();

  const handleButtonClick = () => {
    dispatch(setFirstVisitValue(false));
    if (props.onFirstVisit) {
      props.onFirstVisit();
    }
  };

  const goNextStep = () => {
    if (step < 4) {
      setStep(step + 1);
    } else {
      handleButtonClick();
    }
    dispatch(setFirstVisitValue(false));
  };

  const stepConfigurationsDefault: StepConfiguration[] = [
    {
      title: "Welcome!",
      stepNumber: 0,
      stepDescription:
        "Welcome to the ultimate Formula 1 experience! Get ready for the Las Vegas GP 2023, the wildest race ever. With just one click, you can swap your face with your favorite F1 champions. Let the fun begin!",
      photoResource: WelcomePhoneScreen,
    },
    {
      title: "STEP 1",
      stepNumber: 1,
      stepDescription:
        "Dive into the 'Snap to Action' section and upload your photo.",
      photoResource: Step1PhoneScreen,
    },
    {
      title: "STEP 2",
      stepNumber: 2,
      stepDescription:
        "Take a chance! You have 2 rounds to play and swap your face with F1 champions. Get 3 different results with your face in each round.",
      photoResource: Step2PhoneScreen,
    },
    {
      title: "STEP 3",
      stepNumber: 3,
      stepDescription:
        "Download your results, and for more customization, consider upgrading.",
      photoResource: Step3PhoneScreen,
    },
    {
      title: "STEP 4",
      stepNumber: 4,
      stepDescription:
        "Share your epic results with friends and on social media. Let the laughter and amazement begin! 🏎 📸 🤳 #F1Swap",
      photoResource: Step4PhoneScreen,
    },
  ];

  const stepConfigurations = useRef<StepConfiguration[] | undefined>(
    stepConfigurationsDefault
  );

  const renderStep = useMemo(() => {
    const currentStepConfig = stepConfigurations?.current?.find(
      (config) => config.stepNumber === step
    );

    if (currentStepConfig) {
      return currentStepConfig;
    }
  }, [step, stepConfigurations]);

  return (
    <article className="bg-black bg-opacity-40 flex w-full h-full flex-col fixed items-center justify-center z-40">
      <div className="border-[color:var(--Rosso-F1,#E10600)] bg-zinc-900 flex min-w-[310px] w-[80%] h-[65%] min-h-[620px] flex-col pl-3.5 pr-3.5 rounded-2xl border-2 border-solid absolute">
        <div className="flex justify-between">
          <div>
            <h1 className="text-white font-Formular1R text-2xl mt-6 max-md:pl-1.5">
              <>{renderStep?.title}</>
            </h1>
            {step > 0 ? (
              <div className="ml-1">
                <FirstLoginStepper maxSteps={4} currentStep={step} />
              </div>
            ) : null}
          </div>
          {/* X icon diplayed only if not step 0  */}
          {step > 0 ? (
            <div
              onClick={handleButtonClick}
              className="w-[15px] overflow-hidden max-w-full mt-4"
            >
              <img
                loading="lazy"
                src={XIcon}
                className="w-[15px] overflow-hidden max-w-full mt-2"
                alt="Profile"
              />
            </div>
          ) : null}
        </div>
        <p className="text-white text-xs w-full mt-4 m-auto h-20 px-1.5">
          {renderStep?.stepDescription}
        </p>
        <div className="flex flex-col self-center justify-self-end">
          <div className="flex-col self-center relative flex w-full h-full fixed max-md:mt-10 mb-9">
            {/* w-40 h-64 */}
            <img
              src={renderStep?.photoResource}
              className="w-3/4 object-center self-center ml-14"
              alt="Phone Screen"
            />
          </div>
          <div className="mb-10 self-center">
            <div
              className="bg-red-600 self-center flex w-[208px] max-w-full flex-col px-5 rounded-[31px]"
              onClick={goNextStep}
            >
              <div className="self-center">
                <p className="text-white font-Formular1B text-12 pt-2 pb-2">
                  {step > 0 ? "NEXT" : "OK"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default FirstLoginWizard;
