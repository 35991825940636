import React, { useState } from "react";
import RoulettePro from "react-roulette-pro";
import "react-roulette-pro/dist/index.css";

const prizes = [
  {
    image: "https://i.ibb.co/6Z6Xm9d/good-1.png",
  },
  {
    image: "https://i.ibb.co/T1M05LR/good-2.png",
  },
  {
    image: "https://i.ibb.co/Qbm8cNL/good-3.png",
  },
  {
    image: "https://i.ibb.co/5Tpfs6W/good-4.png",
  },
  {
    image: "https://i.ibb.co/64k8D1c/good-5.png",
  },
];

const winPrizeIndex = 0;

const reproductionArray = (array = [], length = 0) => [
  ...Array(length)
    .fill("_")
    .map(() => array[Math.floor(Math.random() * array.length)]),
];

const reproducedPrizeList = [
  ...prizes,
  ...reproductionArray(prizes as unknown as any, prizes.length * 3),
  ...prizes,
  ...reproductionArray(prizes as unknown as any, prizes.length),
];

const generateId = () =>
  `${Date.now().toString(36)}-${Math.random().toString(36).substring(2)}`;

const prizeList = reproducedPrizeList.map((prize) => ({
  ...prize,
  id:
    typeof crypto.randomUUID === "function"
      ? crypto.randomUUID()
      : generateId(),
}));

const WheelSpinner = () => {
  const [start, setStart] = useState(false);

  const prizeIndex = prizes.length * 4 + winPrizeIndex;

  const handleStart = () => {
    setStart((prevState) => !prevState);
  };

  const handlePrizeDefined = () => {
    console.log("🥳 Prize defined! 🥳");
  };

  return (
    <div className="flex flex-col w-full h-full">
      <h1 className="text-white text-center text-4xl tracking-tight max-w-[317px] self-center mt-10">
        Spin the Wheel
      </h1>
      <p className="text-white text-opacity-70 text-center text-lg leading-[120%] tracking-tight self-center">
        2 spins left
      </p>
      <button
        className="border bg-white self-center flex w-[68px] max-w-full flex-col mt-11 px-5 py-2.5 rounded-lg border-solid border-white max-md:mt-10"
        aria-label="Spin"
        onClick={handleStart}
      >
        Gira
      </button>
      <img
        loading="lazy"
        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d0c2df49-fbaf-4cf7-a8b6-e2d7979dfed9?apiKey=d620e652ac7547af9e9aed0632da3a18&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d0c2df49-fbaf-4cf7-a8b6-e2d7979dfed9?apiKey=d620e652ac7547af9e9aed0632da3a18&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d0c2df49-fbaf-4cf7-a8b6-e2d7979dfed9?apiKey=d620e652ac7547af9e9aed0632da3a18&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d0c2df49-fbaf-4cf7-a8b6-e2d7979dfed9?apiKey=d620e652ac7547af9e9aed0632da3a18&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d0c2df49-fbaf-4cf7-a8b6-e2d7979dfed9?apiKey=d620e652ac7547af9e9aed0632da3a18&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d0c2df49-fbaf-4cf7-a8b6-e2d7979dfed9?apiKey=d620e652ac7547af9e9aed0632da3a18&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d0c2df49-fbaf-4cf7-a8b6-e2d7979dfed9?apiKey=d620e652ac7547af9e9aed0632da3a18&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d0c2df49-fbaf-4cf7-a8b6-e2d7979dfed9?apiKey=d620e652ac7547af9e9aed0632da3a18&"
        className="aspect-[4.69] object-cover object-center w-full overflow-hidden self-stretch mt-7"
        alt=""
      />
      <RoulettePro
        prizes={prizeList}
        prizeIndex={prizeIndex}
        start={start}
        onPrizeDefined={handlePrizeDefined}
      />
    </div>
  );
};

export default WheelSpinner;
