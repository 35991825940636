import { Teams } from "../../data/data";
import TeamsCard from "../TeamsCard";
import F1Car from "../../assets/img/correctCar.png";
import TeamsImage from "../../assets/SVG/teamsImage.svg";
import DriversCart from "../Homepage/components/DriversCart/DriversCart";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface SelectTeamProps {
  onSelect: (team: any) => void;
}

const SelectTeam: React.FC<SelectTeamProps> = ({ onSelect }) => {
  const handleSelect = (team: any) => {
    if (onSelect) {
      onSelect(team);
    }
  };

  const selectedCharacters = useSelector(
    (rootState: RootState) => rootState.characters.selectedCharacters
  );

  useEffect(() => {
    console.log(selectedCharacters);
  }, [selectedCharacters]);

  return (
    <div className="flex flex-col gap-10">
      {/* large image section */}
      <div className="flex items-start flex-col">
        <img src={TeamsImage} alt="" className="w-screen" />
      </div>
      {/* teams section */}
      <div className="flex flex-col gap-4 items-center mb-60">
        {Teams.map((team) => (
          <TeamsCard
            name={team.name}
            bgColor={team.bg}
            key={team.id}
            onClick={() => handleSelect(team)}
            bgImage={F1Car}
          />
        ))}
      </div>
      <div className="fixed bottom-0 w-full">
        <DriversCart maxDrivers={30} selectedDrivers={selectedCharacters} />
      </div>
    </div>
  );
};
export default SelectTeam;
