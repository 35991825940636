import { useEffect, useState } from "react";
import SelectTeam from "../../components/SelectTeam";
import SelectCharacter from "../../components/SelectCharacter";
import { useLocation } from "react-router-dom";
import UpgradeModal from "../../components/Homepage/components/UpgradeModal/UpgradeModal";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface ChooseProps {
  showBackBar: boolean;
  handleBackBarClick: (newValue: boolean) => void;
}

const Choose: React.FC<ChooseProps> = ({ showBackBar, handleBackBarClick }) => {
  const [showTeam, setShowTeam] = useState(true);
  const [firstVisit, setFirstVisit] = useState<boolean>(true);
  const [data, setData] = useState<any>();
  useEffect(() => {
    setShowTeam(!showBackBar);
  }, [showBackBar]);

  const location = useLocation();
  console.log("location", location);

  const handleTeamSelect = (values: any) => {
    setShowTeam(false);
    handleBackBarClick(!showTeam);
    setData(values);
  };

  const handleFirstVisit = () => {
    setFirstVisit(false);
  };

  useEffect(() => {
    if (firstVisit) {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    } else {
      document.getElementsByTagName("body")[0].style.overflow = "visible";
    }
  }, [firstVisit]);

  const seenUpgradeModal = useSelector(
    (rootState: RootState) => rootState.userReducer.seenUpgradeModal
  );
  return (
    <div>
      {showTeam ? (
        <>
          {firstVisit && !seenUpgradeModal && (
            <UpgradeModal onFirstVisit={handleFirstVisit} />
          )}
          <SelectTeam onSelect={handleTeamSelect} />
        </>
      ) : (
        data && <SelectCharacter data={data} />
      )}
    </div>
  );
};
export default Choose;
