import desktopRaceImage from "../../assets/PNG/f1-race-swapper-desktop.png"

const DesktopView = () => {

  return (
    <div className="flex flex-col justify-between items-center text-white h-screen bg-gradient-to-b from-black from-[-13.49%] to-[#15151D] to-[19.33%]">
      <div className="max-w-[750px] mb-12">
        <h1 className="pt-20 font-Formular1 text-[40px] text-center mb-6">
          F1 RACE SWAPPER
        </h1>
        <p className="text-center font-['Titillium Web'] px-5">
          Apologies, but our service is currently unavailable for the desktop
          version. For the full experience, hop on your mobile device to enjoy
          our web app! It's where the Formula 1 magic happens!
        </p>
      </div>
      <div className="max-w-[480px] 2xl:max-w-[620px] h-auto">
        <img className="w-full h-full" src={desktopRaceImage} alt="" />
      </div>
    </div>
  );
};

export default DesktopView;
