import React from "react";

interface ToggleButtonProps {
  on: boolean;
  toggle: (value: boolean) => void;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({ on, toggle }) => {
  const handleClick = () => {
    toggle(!on);
  };

  return (
    <button
      onClick={handleClick}
      className={`focus:ring-0 ${on ? "justify-end" : "justify-start"} ${
        on ? "bg-green-500" : "bg-gray-500"
      } w-12 h-6 p-0 mr-5 rounded-full flex transition duration-500 shadow-2xl`}
    >
      <span
        className={`bg-gray-300 transition duration-500 rounded-full w-1/2 h-full m-0 p-0 shadow-xl`}
      ></span>
    </button>
  );
};

export default ToggleButton;
