import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { asyncAddRemoteResources } from '../actions/freeTrialActions'



export interface UserState {
    isFirstLoginWidardDone: boolean,
    freeSpinsLeft: number,
    readAboutData: boolean,
    seenUpgradeModal: boolean
    totalAmmount: number
    emailPremium: string
    hasUserAgreed: boolean
}

const initialState: UserState = {
    isFirstLoginWidardDone: false,
    freeSpinsLeft: 2,
    readAboutData: false,
    seenUpgradeModal: false,
    totalAmmount: 0,
    emailPremium: "",
    hasUserAgreed: false
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        decrementFreeSpinsLeft: (state) => {
            state.freeSpinsLeft -= 1
        },
        updateABoutData: (state: any, action: PayloadAction<any>) => {
            state.readAboutData = action.payload
        },
        updateSeenUpgradeModal: (state: any, action: PayloadAction<any>) => {
            state.seenUpgradeModal = action.payload
        },
        setTotalAmmount: (state: any, action: PayloadAction<number>) => {
            state.totalAmmount = action.payload
        },
        setEmailPremium: (state: any, action: PayloadAction<string>) => {
            state.emailPremium = action.payload
        },
        setHasUserAgreed: (state: any, action: PayloadAction<boolean>) => {
            state.hasUserAgreed = action.payload
        },
    }
})

// Action creators are generated for each case reducer function
export const { decrementFreeSpinsLeft, updateABoutData, updateSeenUpgradeModal, setTotalAmmount, setEmailPremium, setHasUserAgreed } = userSlice.actions

export default userSlice.reducer