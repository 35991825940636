import CityBackground from "../../assets/PNG/cityBackground.png";
import UploadCardImage from "../../assets/PNG/alonsoFans.png";
import ArrowIcon from "../../assets/SVG/arrowIcons.svg";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApplicationLoader from "../../components/ApplicationLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  
  clearRemoteResources,
  freeTrialSelectedPrizeNumber,
} from "../../redux/reducers/imageReducer";
import {
  WheelRacingTeams,  
} from "../WheelOfFortune/WheelOfFortune";
import {
  asyncAddRemoteResources,
  asyncAddRemoteResourcesPayload,
} from "../../redux/actions/freeTrialActions";
import  {  RootState } from "../../redux/store";
import { ThunkDispatch } from "@reduxjs/toolkit";

const Upload = () => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const freeSpinsLeft = useSelector(
    (rootState: RootState) => rootState.userReducer.freeSpinsLeft
  );

  const goToAboutData = () => {
    navigate("/about-your-data");
  };

  const openCamera = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: any) => {
    setIsLoading(true);
    const file = event.target.files?.[0];
    //if (freeSpinsLeft > 0) {
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const image = reader.result;
          console.log("Image URL:", image);
          const newPrizeNumber: number = Math.floor(
            Math.random() * WheelRacingTeams.length
          );
          const winningTeamName =
          WheelRacingTeams[newPrizeNumber].option;
          dispatch(freeTrialSelectedPrizeNumber(newPrizeNumber));
          const objToDispatch: asyncAddRemoteResourcesPayload = {
            base64UserImage: image,
            selectedTeam: winningTeamName,
          };
          dispatch(clearRemoteResources());
          dispatch(asyncAddRemoteResources(objToDispatch));
          navigate("/download");
        };
        reader.readAsDataURL(file);
      }
    //} else {
      //setTimeout(() => {
        //setIsLoading(true);
      //}, 2000);
      //setTimeout(() => {
        //navigate("/wheel-of-fortune");
      //}, 3000);
    //}
  };

  return (
    <>
      {isLoading ? (
        <ApplicationLoader />
      ) : (
        <div className="flex flex-col  w-full">
          {/* will take the middle information */}
          <div className="flex flex-col flex-1 w-screen items-center mb-8">
            <img
              src={CityBackground}
              alt=""
              className="w-screen"
              height={"284.468px"}
            />
            <div className="absolute flex flex-col justify-between">
              {/* the TILE? */}
              <h1 className="uppercase font-Formular1B text-white text-center mt-10 mb-10">
                <span className="text-4xl text-RossoF1 title-effect">
                  F1Swap
                </span>
                <br /> Formula 1 {currentYear}

              </h1>
              {/* the card */}
              <div
                className="flex flex-col w-327 h-235 items-start border-4 rounded-md border-RossoF1"
                onClick={openCamera}
              >
                <div className="flex items-start">
                  <img src={UploadCardImage} className="w-full h-full" alt="" />
                </div>
                <div className="flex gap-2 pt-4 pr-0 pb-4 pl-4 self-stretch">
                  <div className="font-Formular1B text-white text-xl font-medium uppercase flex items-center justify-center">
                    Snap into action!
                  </div>
                  <div className="flex items-center justify-center mt-[6px]">
                    <img src={ArrowIcon} alt="" className="h-6 w-6" />
                    <input
                      type="file"
                      ref={fileInputRef}
                      accept="image/*"
                      capture="user"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              </div>
              {/* the copy */}
              <div className="w-327 h-234 mb-44">
                <p className="text-white pt-6 font-normal text-12 font-[Titillium Web]">
                  Ready to experience the thrill of <strong>Formula 1</strong>
                  like never before? With our <strong>face swapping app</strong>
                  , you can become a Formula 1 driver in an instant! Test it and
                  join the craziest race of the year! <br />
                  <br />
                  Just one click away! <strong>Tap 'SNAP'</strong> and transform
                  into a true Formula 1 champion! <br />
                  <br />
                  What are you waiting for? Let your passion for speed and
                  Formula 1 run wild. Use the web app now and share the results
                  with friends on socials! 🏎 💨 🤳 <strong>#f1swap</strong>
                </p>
              </div>
            </div>
          </div>
          {/* will take the lower card */}
          <div className="flex w-screen p-6 justify-center items-center rounded-t-2xl bg-RossoF1 bottom-0 fixed">
            <div
              className="flex flex-1 p-2 items-end gap-2 rounded-md border-4 border-white"
              onClick={goToAboutData}
            >
              <p className="font-Formular1B text-xl font-medium text-white pl-2">
                WHAT ABOUT <br /> YOUR DATA?
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Upload;
