
export const Teams = [
  {
    id: "8",
    name: "Aston Martin",
    bg: "rgba(0, 90, 80, 0.70)",
    color: "rgba(0, 90, 80)",
    backendKey: "Aston Martin",
    characters: [
      {
        id: "1",
        name: "Lance Stroll",
      },
      {
        id: "2",
        name: "Fernando Alonso",
      },
      {
        id: "3",
        name: "Be the Manager",
      },
    ],
  },
  {
    id: "3",
    name: "Alpine",
    backendKey: "BWT ALPINE",
    bg: "rgba(252, 75, 199, 0.75)",
    color: "rgba(252, 75, 199)",
    characters: [
      {
        id: "1",
        name: "Esteban Ocon",
      },
      {
        id: "2",
        name: "Pierre Gasly",
      },
      {
        id: "3",
        name: "Be the Manager",
      },
    ],
  },
  {
    id: "4",
    name: "Kick Sauber",
    bg: "rgba(165, 14, 45, 0.75)",
    color: "rgba(165, 14, 45)",
    backendKey: "Alfa Romeo",
    characters: [
      {
        id: "1",
        name: "Valtteri Bottas",
      },
      {
        id: "2",
        name: "Guanyu Zhou",
      },
      {
        id: "3",
        name: "Be the Manager",
      },
    ],
  },
  {
    id: "6",
    name: "Red Bull Racing",
    bg: "rgba(35, 50, 106, 0.75)",
    color: "rgba(35, 50, 106)",
    backendKey: "Red Bull",
    characters: [
      {
        id: "1",
        name: "Max Verstappen",
      },
      {
        id: "2",
        name: "Sergio Perez",
      },
      {
        id: "3",
        name: "Be the Manager",
      },
    ],
  },
  {
    id: "1",
    name: "Ferrari",
    backendKey: "Ferrari",
    bg: "rgba(225, 6, 0, 0.65)",
    color: "rgba(225, 6, 0)",
    characters: [
      {
        id: "1",
        name: "Charles Leclerc",
      },
      {
        id: "2",
        name: "Carlos Sainz Jr",
      },
      {
        id: "3",
        name: "Be the Manager",
      },
    ],
  },
  {
    id: "7",
    name: "Haas F1 Team",
    backendKey: "Haas",
    bg: "rgba(250, 250, 250, 0.75)",
    color: "#86868f",
    characters: [
      {
        id: "1",
        name: "Nico Hulkenberg",
      },
      {
        id: "2",
        name: "Kevin Magnussen",
      },
      {
        id: "3",
        name: "Be the Manager",
      },
    ],
  },
  {
    id: "5",
    name: "McLaren",
    backendKey: "Mclaren",
    bg: "rgba(255, 128, 0, 0.70)",
    color: "rgba(255, 128, 0)",
    characters: [
      {
        id: "1",
        name: "Oscar Piastri",
      },
      {
        id: "2",
        name: "Lando Norris",
      },
      {
        id: "3",
        name: "Be the Manager",
      },
    ],
  },
  {
    id: "2",
    name: "Mercedes",
    backendKey: "Mercedes",
    bg: "rgba(0, 161, 156, 0.70)",
    color: "rgba(0, 161, 156)",
    characters: [
      {
        id: "1",
        name: "Lewis Hamilton",
      },
      {
        id: "2",
        name: "George Russell",
      },
      {
        id: "3",
        name: "Be the Manager",
      },
    ],
  },
  {
    id: "10",
    name: "Williams",
    bg: "rgba(0, 162, 224, 0.70)",
    color: "rgba(0, 162, 224)",
    backendKey: "William",
    characters: [
      {
        id: "1",
        name: "Alexander Albon",
      },
      {
        id: "2",
        name: "Logan Sargeant",
      },
      {
        id: "3",
        name: "Be the Manager",
      },
    ],
  },
  {
    id: "9",
    name: "RB",
    bg: "rgba(0, 41, 64, 0.75)",
    color: "rgba(0, 41, 64)",
    backendKey: "Scuderia Alphatauri",
    characters: [
      {
        id: "1",
        name: "Daniel Ricciardo",
      },
      {
        id: "2",
        name: "Yuki Tsunoda",
      },
      {
        id: "3",
        name: "Be the Manager",
      },
    ],
  },
];
