import React, { useState } from "react";
import {
  useStripe,
  useElements,
  ExpressCheckoutElement,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { StripeElements } from "@stripe/stripe-js";
import axios from "axios";
import { localIpDevServer } from "../../App";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { Teams } from "../../data/data";
import SecondaryButton from "../../components/Shared/Buttons/SecondaryButton/SecondaryButton";
import { findTeamAndId } from "./utils";

interface Image {
  teamName: string;
  characterId: number;
}
interface PremiumFlowPayload {
  email: string;
  images: Image[];
  base64: string;
}

const CheckoutPage = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<any>();
  //const [paymentRequest, setPaymentRequest] = useState<any>()
  const numberOfSelectedPremiumSwap = useSelector(
    (rootState: RootState) => rootState.characters.selectedCharacters.length
  );
  const premiumEmail = useSelector(
    (rootState: RootState) => rootState.userReducer.emailPremium
  );
  const premiumUserImage = useSelector(
    (rootState: RootState) => rootState.rootReducer.premiumSwapImage
  );
  const selectedPremiumSwap = useSelector(
    (rootState: RootState) => rootState.characters.selectedCharacters
  );
  //@ts-ignore
  const onConfirm = async (event) => {
    if (!stripe) {
      // Stripe.js hasn't loaded yet.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    //@ts-ignore
    const { error: submitError } = await elements?.submit();
    if (submitError) {
      setErrorMessage(submitError.message);
      return;
    }

    axios
      .post(`${localIpDevServer}${"/api/create-premium-intent"}`, {
        amount: numberOfSelectedPremiumSwap * 4 * 100,
      })
      .then(async (res) => {
        const clientSecret = res.data;
        console.log("res.data", clientSecret)
        const { error } = await stripe.confirmPayment({
          // `elements` instance used to create the Express Checkout Element
          elements: elements as StripeElements,
          // `clientSecret` from the created PaymentIntent
          clientSecret,
          confirmParams: {
            return_url: "https://f1swap.com/thank-you",
            //return_url: "http://localhost:3000/thank-you"
          },
        })
        // Confirm the PaymentIntent using the details collected by the Express Checkout Element
        if (error) {
          // This point is only reached if there's an immediate error when
          // confirming the payment. Show the error to your customer (for example, payment details incomplete)
          setErrorMessage(error.message as string);
        } else {
          const teamsAndId = selectedPremiumSwap.map((driver) => {
            return findTeamAndId(driver);
          });
          axios.post(`${localIpDevServer}${"/api/request-premium"}`, {
            email: premiumEmail,
            images: teamsAndId,
            base64: premiumUserImage,
          });
          // The payment UI automatically closes with a success animation.
          // Your customer is redirected to your `return_url`.
        }
      });
  };

  return (
    <div id="checkout-page">
      <ExpressCheckoutElement
        onConfirm={onConfirm}
        options={{
          wallets: {
            applePay: "always",
          },
        }}
      />
      <PaymentElement options={{}} />
      <div className="flex items-center justify-center mt-6">
        <SecondaryButton
          onClick={onConfirm}
          enabled={true}
          text={"pay"}
          width="full"
          height="12"
          fontSize="base"
        />
      </div>

      {errorMessage && <div>{errorMessage}</div>}
    </div>
  );
};

export default CheckoutPage;
