import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import imageReducer from "./reducers/imageReducer";
import storage from "redux-persist/lib/storage";
import thunk from 'redux-thunk'
import characterReducer from "./reducers/characterReducer";
import homeReducer from "./reducers/homeReducer";
import userReducer from "./reducers/userReducer";

export const rootReducer = combineReducers({
  rootReducer: imageReducer,
  characters: characterReducer,
  homeStates: homeReducer,
  userReducer: userReducer
});

const persistConfig = {
  key: "root",
  storage: storage,
};


const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

