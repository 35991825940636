import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import Choose from "../Choose";
import Upload from "../Upload";
import FirstLoginWizard from "../../components/Homepage/components/FirstLoginWizard/FirstLoginWizard";
import arrowBack from "../../assets/SVG/arrowBack.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import {
  setActiveTabValue,
  setFirstVisitValue,
} from "../../redux/reducers/homeReducer";

const Home = () => {
  const initialActiveTab = useSelector(
    (rootState: RootState) => rootState.homeStates.activeTab
  );
  const initialFirstVisit = useSelector(
    (rootState: RootState) => rootState.homeStates.firstVisit
  );

  const [activeTab, setActiveTab] = useState<string>(initialActiveTab);
  const [firstVisit, setFirstVisit] = useState<boolean>(initialFirstVisit);
  const [showBackBar, setShowBackBar] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBackBarClick = () => {
    setShowBackBar(!showBackBar);
  };

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    dispatch(setActiveTabValue(tab));
  };

  /* this state will be updated by redux */
  const premiumUser = true;

  const handleFirstVisit = () => {
    setFirstVisit(false);
    dispatch(setFirstVisitValue(false));
  };

  const backBarClicked = () => {
    console.log(!showBackBar, ": the value of backbar");
    setShowBackBar(!showBackBar);
  };

  // useEffect(() => {
  //   if (!readAboutData) {
  //     navigate("/about-your-data");
  //   }
  // }, []);

  return (
    <>
      {activeTab === "choose" && showBackBar && (
        <div className=" w-full p-4 bg-red-600">
          <img
            src={arrowBack}
            alt="Arrow Back"
            width={30}
            onClick={backBarClicked}
          />
        </div>
      )}

      <div className="flex flex-column justify-center gap-8 w-full h-full">
        {firstVisit && <FirstLoginWizard onFirstVisit={handleFirstVisit} />}

        {/* { visibleBackBar && <div className="fixed top-0 w-full"><BackBar /></div>} per BackBAr quando sono in selected characters*/}
        <div className="flex flex-col w-full mt-10">
          <div className="flex flex-row justify-center gap-gap14">
            <div
              className={`flex flex-col w-tabWidth justify-center items-center gap-1`}
              onClick={() => handleTabClick("upload")}
            >
              <span
                className={`text-white font-Formular1B text-xl font-medium justify-center items-center`}
              >
                UPLOAD
              </span>
              <div className="h-underlineHeight">
                {activeTab === "upload" && (
                  <div className="w-tabWidth h-underlineHeight bg-RossoF1 border-RossoF1 rounded-lg"></div>
                )}
              </div>
            </div>
            <div
              className={twMerge(
                `flex flex-col w-tabWidth justify-center items-center gap-1 pointer-events-none`,
                premiumUser ? "" : "pointer-events-none"
              )}
              onClick={() => handleTabClick("choose")}
            >
              <span
                className={`text-white font-Formular1B text-xl font-medium justify-center items-center`}
              >
                CHOOSE
              </span>
              <div className="h-underlineHeight">
                {activeTab === "choose" && (
                  <div className="w-tabWidth h-underlineHeight bg-RossoF1 border-RossoF1 rounded-lg"></div>
                )}
              </div>
            </div>
          </div>
          <div className="flex-1">
            {activeTab === "upload" && <Upload />}
            {activeTab === "choose" && (
              <Choose
                showBackBar={showBackBar}
                handleBackBarClick={handleBackBarClick}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
