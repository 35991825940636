import { useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { localIpDevServer } from "../../App";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { findTeamAndId } from "../UserForm/utils";
import F1Car from "../../assets/SVG/F1Car.svg";
import PrimaryButton from "../../components/Shared/Buttons/PrimaryButton/PrimaryButton";
import { useDispatch } from "react-redux";
import { clearCharacterArray } from "../../redux/reducers/characterReducer";
import { useNavigate } from "react-router";

const ThankYou: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const redirectStatus = query.get('redirect_status');
  const premiumEmail = useSelector(
    (rootState: RootState) => rootState.userReducer.emailPremium
  );
  const premiumUserImage = useSelector(
    (rootState: RootState) => rootState.rootReducer.premiumSwapImage
  );
  const selectedPremiumSwap = useSelector(
    (rootState: RootState) => rootState.characters.selectedCharacters
  );
  const handleClick = () => {
    dispatch(clearCharacterArray());
    navigate("/home");
  };
  useEffect(() => {
    console.log("Redirect status", redirectStatus)
    const teamsAndId = selectedPremiumSwap.map((driver) => {
      return findTeamAndId(driver);
    });
    if (redirectStatus === "succeeded") {
      axios.post(`${localIpDevServer}${"/api/request-premium"}`, {
        email: premiumEmail,
        images: teamsAndId,
        base64: premiumUserImage,
      });
    }
  }, [redirectStatus])

  return (
    <>
      <div
        className="flex h-40 w-full top-0 left-0 absolute -z-40"
        style={{
          background:
            "linear-gradient(180deg, rgba(85, 3, 0, 0.00) 0%, rgba(85, 3, 0, 0.70) 0.01%, rgba(85, 3, 0, 0.00) 100%)",
        }}
      ></div>
      <div className="flex items-center justify-center mt-8 mb-4">
        <div className="w-232 text-2xl font-normal font-Formular1R text-center text-white mx-0">
          Thanks for your purchase!
        </div>
      </div>
      <div className="flex items-center justify-center">
        <div className=" text-13 font-normal font-Formular1R text-center text-white">
          We hope you will race with us soon again. "Remember to check your
          email because even your results are racing towards you." <br /> 🏎 📸
          🤳 #RaceFace
        </div>
      </div>
      <img alt="" className="w-full mt-6" src={F1Car} />
      <div className="flex items-center justify-center mt-8">
        <PrimaryButton
          enabled={true}
          text={"Back To Home Page"}
          width="[152px]"
          fontSize="xs"
          onClick={handleClick}
        />
      </div>
    </>
  );
};

export default ThankYou;
