import React, { useState } from "react";
import dataImg from "../../assets/img/dataImg.png";
import ToggleButton from "../../components/Shared/Buttons/ToggleButton/ToggleButton";
import PrimaryButton from "../../components/Shared/Buttons/PrimaryButton/PrimaryButton";
import BackBar from "../../components/Shared/BackBar/BackBar";
import { useDispatch, useSelector } from "react-redux";
import { setHasUserAgreed, updateABoutData } from "../../redux/reducers/userReducer";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../redux/store";

const AboutYourData: React.FC = () => {
  const [toggleOn, setToggleOn] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAgreed = () => {
    dispatch(updateABoutData(true));
    dispatch(setHasUserAgreed(true))
    navigate("/home");
  };

  const readAboutData = useSelector(
    (rootState: RootState) => rootState.userReducer.readAboutData
  );

  return (
    <div className="w-full h-10 flex flex-col items-center text-gray-100 font-[Titillium Web]">
      <BackBar />
      {/* Image at the top in full width */}
      <div className="flex w-full p-0 16px 16px 16px items-end">
        <img
          src={dataImg}
          alt="Mobile View"
          className="w-full h-full object-cover"
        />
      </div>

      {/* Text below the image */}
      <div className="p-4 pb-40">
        <h6 className="font-bold">Your Privacy Matters to Us</h6>
        <p>
          We understand that sharing your personal data, especially biometric
          data, is a sensitive matter. At Sophany, we prioritize your privacy
          and want to assure you that your trust is well-placed.
        </p>
        <br />

        <h6 className="font-bold">Why Do We Collect Your Data?</h6>
        <p>
          We ask for your permission to temporarily collect and process your
          biometric data contained in your photos. This data is essential to
          ensure you can fully enjoy all the features our app has to offer,
          enhance your safety and security while using our service, and provide
          you with effective customer support.
        </p>
        <br />

        <h6 className="font-bold">Our Commitment to Your Privacy</h6>
        <p>Here's what you should know:</p>
        <ol className="list-decimal pl-6">
          <li className="mb-2">
            <strong>Data Deletion:</strong> After rendering your photo, we
            promptly and securely delete your personal face photo from our
            database. We do not retain your biometric data once the rendering
            process is complete.
          </li>
          <li className="mb-2">
            <strong>No Personal Use:</strong> We absolutely do not use your
            biometric data for personal purposes. Your data is solely used to
            improve your experience within the app.
          </li>
          <li className="mb-2">
            <strong>No Third-Party Sharing:</strong> We do not share your
            biometric data with any third parties, except when required by
            applicable laws, rules, regulations, ordinances, court or regulator
            orders, or pursuant to a valid warrant or subpoena.
          </li>
          <li className="mb-2">
            <strong>No Selling of Data:</strong> We do not sell any of your
            data.
          </li>
        </ol>

        <br />

        <p>
          {" "}
          For more detailed information about how we handle your data, please
          read our Privacy Notice. At Sophany, your privacy is a top priority,
          and we are committed to ensuring the safety and security of your
          personal information.
        </p>
        <br />
        <p>
          Thank you for choosing our app. If you have any questions or concerns,
          please do not hesitate to reach out to our support team. Your trust is
          important to us, and we aim to uphold it at all times.
        </p>
      </div>

      {/* Bottom flag button */}
      <div className="fixed bottom-0 w-full p-4 bg-zinc-900 text-white text-center shadow-top">
        <div className="flex justify-start mb-4">
          <ToggleButton on={toggleOn} toggle={setToggleOn} />
          <small>I’ve read and agree with the above</small>
        </div>
        <PrimaryButton
          enabled={toggleOn}
          text={"I agree"}
          onClick={handleAgreed}
        />
      </div>
    </div>
  );
};

export default AboutYourData;
