import React from "react";
import _ from "lodash";
interface FirstLoginStepperProps {
  maxSteps: number;
  currentStep: number;
}

const FirstLoginStepper: React.FC<FirstLoginStepperProps> = ({
  maxSteps,
  currentStep,
}) => {
  return (
    <div className="flex w-full h-2 my-1">
      {_.map(_.range(1, maxSteps + 1), (step) => (
        <div
          key={step}
          className={
            currentStep === step
              ? "bg-white w-3.5 h-1 rounded-3xl m-0.5"
              : "bg-white bg-opacity-40 w-2.5 h-1 rounded-3xl m-0.5"
          }
        /> //w-2.5 h-1
      ))}
    </div>
  );
};

export default FirstLoginStepper;
