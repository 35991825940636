import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface HomeState {
    firstVisit: boolean;
    activeTab: string;
}

const initialState: HomeState = {
     firstVisit: true,
    activeTab: "upload"
}

export const homeSlice = createSlice({
    name: "homeSates",
    initialState: initialState,
    reducers: {
        setFirstVisitValue: (state: any, action: PayloadAction<any>) =>{
            state.firstVisit = action.payload
        },
        setActiveTabValue: (state: any, action: PayloadAction<any>) =>{
            state.activeTab = action.payload
        }
    }
})

// actions to access the reducers
export const {setFirstVisitValue, setActiveTabValue} = homeSlice.actions

export default homeSlice.reducer