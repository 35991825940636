import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { asyncAddRemoteResources } from '../actions/freeTrialActions'
import { isDoStatement } from 'typescript'

// Mattia: the code below is a simple placeholder.

export interface ImageState {
  remoteResourcesImages: string[],
  wheelOfFortunePrizeNumber: number
  premiumSwapImage: string
}

const initialState: ImageState = {
  remoteResourcesImages: [],
  wheelOfFortunePrizeNumber: 0,
  premiumSwapImage: ""
}

export const imageSlice = createSlice({
  name: 'freeTrial',
  initialState,
  reducers: {
    addRemoteResources: (state, action: PayloadAction<string[]>) => {
      state.remoteResourcesImages = action.payload
    },
    freeTrialSelectedPrizeNumber: (state, action: PayloadAction<number>) => {
      state.wheelOfFortunePrizeNumber = action.payload
    },
    setPremiumSwapImage: (state, action: PayloadAction<string>) => {
      state.premiumSwapImage = action.payload
    },
    clearRemoteResources: (state: ImageState) => {
      state.remoteResourcesImages = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(asyncAddRemoteResources.fulfilled, (state, action) => {
      state.remoteResourcesImages = action.payload
    })
  },
})

// Action creators are generated for each case reducer function
export const { addRemoteResources, freeTrialSelectedPrizeNumber, setPremiumSwapImage, clearRemoteResources } = imageSlice.actions

export default imageSlice.reducer