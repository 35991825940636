import Team from "../../assets/img/teamsCar.png";
import TeamsCard from "../TeamsCard";
import Flag from "../../assets/img/flag.jpeg";
import { Key, useEffect } from "react";
import ApplicationLoader from "../ApplicationLoader";
import { useDispatch, useSelector } from "react-redux";
import { addCharacter } from "../../redux/reducers/characterReducer";
import { RootState } from "../../redux/store";
import DriversCart from "../Homepage/components/DriversCart/DriversCart";

interface SelectCharacterProps {
  data: any;
}

const SelectCharacter: React.FC<SelectCharacterProps> = ({ data }) => {
  //const [isLoading, setIsLoading] = useState<boolean>(false);
  //const navigate = useNavigate();
  const dispatch = useDispatch();

  const chooseCharacter = (name: string) => {
    //setIsLoading(true);
    dispatch(addCharacter(name));
    // setTimeout(() => {
    //   setIsLoading(false);
    //   navigate("/home");
    // }, 2000);
    /* const endpoint = `${localIpDevServer}${selectCharacterRoute}`;
    axios
      .post(endpoint, {
        name: name,
      })
      .then((res) => {
        console.log("API Response:", res.data);
        setTimeout(() => {
          setIsLoading(false);
          navigate("/home");
        }, 2000);
      })
      .catch((err) => {
        console.error("Error submitting data:", err);
        setTimeout(() => {
          setIsLoading(false);
          navigate("/home");
        }, 2000);
      }); */
  };

  const selectedCharacters = useSelector(
    (rootState: RootState) => rootState.characters.selectedCharacters
  );

  useEffect(() => {
    console.log(selectedCharacters);
  }, [selectedCharacters]);

  return (
    <>
      {false ? (
        <ApplicationLoader />
      ) : (
        <div className="flex flex-col gap-10">
          {/* large image section */}
          <div className="flex items-start flex-col">
            <div className="relative flex flex-col items-start w-full h-175 gap-2">
              <div
                className={`absolute inset-0`}
                style={{
                  background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, ${data.bg} 100%)`,
                }}
              ></div>
              <img src={Team} alt="" className="w-full h-full object-cover" />
              <div className="absolute inset-0 flex flex-row items-end justify-start p-4">
                <div className="font-Formular1B text-white text-xl font-medium uppercase flex w-40 items-end">
                  {data.name}
                </div>
              </div>
            </div>
          </div>
          {/* characters section */}
          <div className="flex flex-col gap-4 items-center mb-60">
            {data.characters.map(
              (character: { name: string; id: Key | null | undefined }) => (
                <TeamsCard
                  name={character.name}
                  bgColor={"#000"}
                  key={character.id}
                  onClick={chooseCharacter}
                  bgImage={Flag}
                  className=""
                />
              )
            )}
            <div className="fixed bottom-0 w-full">
              <DriversCart
                maxDrivers={30}
                selectedDrivers={selectedCharacters}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default SelectCharacter;
