import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export interface CharacterState {
    selectedCharacters: string[]
}

const initialState: CharacterState = {
    selectedCharacters: []
}

export const characterSlice = createSlice({
    name: "selectedCharacter",
    initialState: initialState,
    reducers: {
      addCharacter: (state, action: PayloadAction<string>) => {
        const characterIndex = state.selectedCharacters.indexOf(action.payload);
  
        if (characterIndex === -1) {
          // Character not in the array, add it
          state.selectedCharacters.push(action.payload);
        } else {
          // Character already in the array, remove it
          state.selectedCharacters.splice(characterIndex, 1);
        }
      },
      deleteSingleCharacter: (state, action: PayloadAction<string>) => {
        return {
          ...state,
          selectedCharacters: state.selectedCharacters.filter(
            (character) => character !== action.payload
          ),
        };
      },
      clearCharacterArray: (state) => {
        state.selectedCharacters = []
      }
    },
  });
  

// creating actions to access the reducers
export const { addCharacter, deleteSingleCharacter, clearCharacterArray } = characterSlice.actions

export default characterSlice.reducer