import { Formik, Form, Field, ErrorMessage } from "formik";
import BackBar from "../../components/Shared/BackBar/BackBar";
import PosingInstructions from "../../assets/PNG/posingInstructions.png";
import arrowBack from "../../assets/SVG/arrowBack.svg";
import CameraIcon from "../../assets/SVG/cameraIcon.svg";
import { useEffect, useRef, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  ExpressCheckoutElement,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { localIpDevServer } from "../../App";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import TotalAmount from "../TotalAmount";
import CheckoutPage from "./CheckoutStripe";
import { values } from "lodash";
import * as Yup from "yup";
import SecondaryButton from "../../components/Shared/Buttons/SecondaryButton/SecondaryButton";
import { Teams } from "../../data/data";
import { setEmailPremium } from "../../redux/reducers/userReducer";

const UserForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .matches(
        /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/,
        "Invalid email address"
      ),
  });
  const initialData = {
    email: "",
  };
  const submitForm = (values: any) => {};
  const handleFormChange = (values: any) => {
    if (Yup.string().email().isValidSync(values.email)) {
      console.log("valid email", values);
      fetch("https://hooks.zapier.com/hooks/catch/13695542/2bz4bd9/", {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({ email: values.email }),
      })
        .then((response) => {
          console.log("Email sent successfully:", response);
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
    }
    console.log(values);
  };

  const stripePromise = loadStripe(
    "pk_live_51NKnQOEukSl2LBnNCLot4d9X5f2MeCHedRf7TnfoZH2GyyTLcJMfauE5z1hjHKhVSyE3UcFd1MNrUG2CpBYmgUZQ00p5typFZm"
  );
  const [clientSecret, setClientSecret] = useState<string | null>(null);

  const numberOfSelectedPremiumSwap = useSelector(
    (rootState: RootState) => rootState.characters.selectedCharacters.length
  );
  const selectedCharacters = useSelector(
    (rootState: RootState) => rootState.characters.selectedCharacters
  );

  useEffect(() => {
    function findTeamAndId(driverSelected: string) {
      for (const team of Teams) {
        for (const character of team.characters) {
          if (character.name === driverSelected) {
            return { teamName: team.name, characterId: character.id };
          } else {
            continue;
          }
        }
      }
    }
    //TODO:put function declaration in a utils file
    const teamsAndId = selectedCharacters.map((driver) => {
      return findTeamAndId(driver);
    });

    console.log(teamsAndId);
  }, []);

  const handleClick = () => {
    navigate("/total-amount");
  };
  return (
    <>
      <TotalAmount />
      <div className="w-full bg-RossoF1 h-[1px] mt-7"></div>
      <Formik
        initialValues={initialData}
        enableReinitialize={true}
        onSubmit={submitForm}
        validationSchema={validationSchema}
      >
        {(formik) => {
          handleFormChange(formik.values);

          console.log("formik errors", formik.errors);

          return (
            <Form>
              <div className="flex items-center justify-center">
                <div className="w-327 text-white font-Formular1R text-xl font-normal mb-9 mt-9">
                  Fill in the following fields to complete your purchase:
                </div>
              </div>
              <div className="flex items-center justify-center">
                <div
                  className="flex flex-col items-start gap-2 mb-9 w-327"
                  onBlur={() => {
                    dispatch(setEmailPremium(formik.values.email));
                  }}
                >
                  <label className="h-5 font-[Titillium Web] text-sm font-normal not-italic text-white gap-1">
                    <span className="text-RossoF1">* </span> {"Email"}
                  </label>
                  <Field
                    type="email"
                    name={`email`}
                    className="flex w-full px-3 py-3 items-center gap-3 rounded bg-transparent focus:bg-transparent border border-white font-sans text-xs font-normal text-white focus:outline-none placeholder:text-white"
                    placeholder="Insert valid email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-white"
                  />
                </div>
              </div>
              <>
                <div className="w-full bg-RossoF1 h-[1px] mb-9"></div>

                <div className="gap-7 items-center flex-col justify-center mb-7">
                  <div className="font-Formular1R text-xl text-white font-normal text-center">
                    <span className=" font-normal font-Formular1R text-center text-white">
                      TOTAL AMMOUNT:
                    </span>
                    <br />
                    <div className="text-5xl mt-2">
                      {selectedCharacters.length * 4} $
                    </div>
                  </div>
                </div>
                {/*TODO: fix condition: if the email field is empty or invalid don't render stripe component */}
                {!formik.errors.email && formik.values.email ? (
                  <div className="flex items-center justify-center flex-col h-fit">
                    <>
                      <Elements
                        stripe={stripePromise}
                        options={{
                          mode: "payment",
                          amount: numberOfSelectedPremiumSwap * 4 * 100,
                          currency: "eur",
                          // Customizable with appearance API.
                          appearance: {
                            theme: "flat",
                            variables: { colorPrimaryText: "#262626" },
                          },
                        }}
                      >
                        <div className="mb-5">
                          <CheckoutPage />
                        </div>
                      </Elements>
                    </>
                  </div>
                ) : (
                  <></>
                )}
                <div className="h-327 w-screen gap-8 p-7 mb-28 mt-5">
                  <div className="flex flex-col mb-9">
                    <div className="font-Formular1R text-white text-xl font-medium uppercase w-188 mb-6">
                      Summary.
                    </div>
                    <div className="w-327">
                      <span className="text-white font-[Titillium Web] text-base font-bold">
                        It's super easy:
                      </span>
                      <p className="text-white font-[Titillium Web]text-sm font-normal mb-4">
                        You have chosen {selectedCharacters.length} drivers, you
                        will receive 8 customised photos for each of them. To
                        complete the process fill the Email field.
                      </p>
                      <span className="text-white font-[Titillium Web] text-base font-bold">
                        Check your inbox!
                      </span>
                      <br />
                      <p className="text-white font-[Titillium Web] text-sm font-normal">
                        Get ready for the fun to hit your inbox! You'll receive
                        an email with all your selected results. Don't forget to
                        check your mailbox and get ready to share the hilarious
                        results with your friends and on social media. Let the
                        Formula 1 face-swapping adventure begin!
                      </p>
                    </div>
                  </div>
                </div>
              </>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default UserForm;
