import React from "react";
import XIcon from "./../../../../assets/icons/x-icon.svg";
import PrimaryButton from "../../../Shared/Buttons/PrimaryButton/PrimaryButton";
import { updateSeenUpgradeModal } from "../../../../redux/reducers/userReducer";
import { useDispatch } from "react-redux";

const UpgradeModal: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  const handleButtonClick = () => {
    dispatch(updateSeenUpgradeModal(true));
    if (props.onFirstVisit) {
      props.onFirstVisit();
    }
  };
  const handleXClick = () => {
    dispatch(updateSeenUpgradeModal(true));
  };

  return (
    <article className="bg-black bg-opacity-40 flex w-full h-full flex-col fixed top-0 items-center justify-center z-40">
      <div className="border-[color:var(--Rosso-F1,#E10600)] bg-zinc-900 flex min-w-[310px] w-[80%] h-[65%] min-h-[620px] flex-col pl-3.5 pr-3.5 rounded-2xl border-2 border-solid absolute">
        <div className="flex justify-between">
          <div>
            <h1 className="text-white uppercase font-Formular1R text-2xl mt-6 max-md:pl-1.5">
              <>UPGRADE</>
            </h1>
          </div>
          <div
            onClick={handleButtonClick}
            className="w-[15px] overflow-hidden max-w-full mt-4"
          >
            <img
              loading="lazy"
              src={XIcon}
              className="w-[15px] overflow-hidden max-w-full mt-2"
              alt="Profile"
              onClick={handleXClick}
            />
          </div>
        </div>
        <p className="text-white text-xs w-full mt-4 m-auto h-20 px-1.5 font-normal font-[Titillium Web]">
          Ready to rev up your experience? 🏁 <br />
          With our customization plan, you can personalize your swap results to
          the max.
          <br />
          Choose your favorite F1 teams and drivers to match your face with
          their iconic looks. <br />
          <br />
          <strong>How does it work?</strong> <br />
          <br />
          <strong>It's super easy:</strong> pick from 5 to 8 drivers, and we'll
          generate 10 images for each one. After payment, we'll email them to
          you. Remember, the minimum is 5, and the maximum is 8, so
          <strong>choose your dream team wisely.</strong> <br />
          <br />
          So, what are you waiting for? Ready to join the race and get your
          personalized Formula 1 transformation? 🏎 📸 🤳
        </p>
        <div className="flex justify-center w-full">
          <div className="flex flex-row gap-2 mb-6 w-full justify-center">
            <PrimaryButton
              enabled={true}
              text={"LET'S GO"}
              fontSize="xs"
              width="32"
              height="7"
              onClick={handleButtonClick}
            />
          </div>
        </div>
      </div>
    </article>
  );
};

export default UpgradeModal;
