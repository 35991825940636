import { useEffect, useLayoutEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
//import { loadStripe } from "@stripe/stripe-js";
import "./App.css";
//@ts-ignore
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import AboutYourData from "./pages/AboutYourData";
import Download from "./pages/Download";
import UserForm from "./pages/UserForm";
import TotalAmount from "./pages/TotalAmount";
import DesktopView from "./pages/DesktopView";
import WheelOfFortune from "./pages/WheelOfFortune/WheelOfFortune";
import ThankYou from "./pages/ThankYou";

export const localIpDevServer = "https://f1swapbenodenew-w56vh2yb7q-uc.a.run.app";
//export const localIpDevServer = "http://localhost:8000"
export const swappingRoute = "/api/swap-single-image";
export const selectCharacterRoute = "/api/select-character";

function App() {
  const [user, setUser] = useState<any>();
  const [events, setEvents] = useState<any>([]);
  const stripePromise = loadStripe(
    "pk_live_51NKnQOEukSl2LBnNCLot4d9X5f2MeCHedRf7TnfoZH2GyyTLcJMfauE5z1hjHKhVSyE3UcFd1MNrUG2CpBYmgUZQ00p5typFZm"
  );
  // const [user, setUser] = useState<any>();
  // const [events, setEvents] = useState<any>([]);
  // const stripePromise = loadStripe(
  //   "pk_test_51NKnQOEukSl2LBnNg33eGFp1ywOHaDLo0jfRFgDSACM5dXeOnFCsh17vpUBIJG74jthuLG51qOQq6jMXl8RvPGiq00OxquZzKW"
  // );

  const [isMobile, setIsMobile] = useState(true);

  const resizeCallback = () => {
    if (window.innerWidth < 576) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useLayoutEffect(() => {
    if (window.innerWidth > 575) {
      setIsMobile(false);
    }

    window.addEventListener("resize", resizeCallback);
    return () => {
      window.removeEventListener("resize", resizeCallback);
    };
  }, [isMobile]);
  // return isMobile;

  useEffect(() => {
    console.log(isMobile);
  }, [isMobile]);

  if (!isMobile) {
    return <DesktopView />;
  } else
    return (
      <>
        <Router>
          <Routes>
            <Route path="/*" element={<Home />} />
            {/*<Route path="/wheel-spinning" element={<WheelSpinner />} />*/}
            <Route path="/wheel-of-fortune" element={<WheelOfFortune />} />
            <Route path="/about-your-data" element={<AboutYourData />} />
            <Route path="/download" element={<Download />} />
            <Route
              path="/total-amount"
              element={<TotalAmount title="test" numberDisplayed={2} />}
            />
            <Route path="/checkout" element={<UserForm />} />
            <Route path="/thank-you" element={<ThankYou />} />
            {/* <Route
              path="/animation"
              element={
                <TrafficLightAnimation source="https://s3-alpha-sig.figma.com/img/99d2/06ea/d698db9a997fcf9b6bcdcdc80539c661?Expires=1700438400&Signature=DRnl5l7iX07LU-pZi85lYw1yfsJiKxv3m~6VN4l-QAzMc1CbS6R95JrUdy4q4x8FqNuVkuZcING3dbM-KqxgQk4ctZroIX02oq6twsmlZJlnwppiEUiJQaxqzQR6twexOJelCJ1KNbHW~Z7AKeIMFqQ2dcHxz4eXDIwbPKok9Wyy~bheTbtA9wTLivgI~HUtsH07ckhDGH~ST9jKa2h9Qdcau5upi1M2gcMx7KIipjPYgV5Pfr2EOJj3-KoxyekkStTf1QYLFdl437OanXefpflgRu6-Yc5jqOBibO~46qe6d6RzWCiohsl1YpPlyN3TbUkjxjkQmWCoijo2rdKA7A__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4" />
              }
            /> */}
            {/* <Route path="/test-upgrade-modal" element={<UpgradeModal />} />\ */}
            {/* <Route
              path="/test-cart-component"
              element={
                <DriversCart maxDrivers={8} selectedDrivers={undefined} />
              }
            /> */}
          </Routes>
        </Router>
      </>
    );
}

export default App;
