import InnerTyre from "../../assets/SVG/innerTyre.svg";
import OuterTyre from "../../assets/SVG/outerTyre.svg";
const ApplicationLoader = () => {
  return (
    <div className="fixed top-0 left-0 w-screen h-screen z-50 bg-black  flex items-center justify-center">
      <img src={OuterTyre} alt="" className="w-67 h-67 relative animate-spin" />
      <img src={InnerTyre} alt="" className="w-10 h-10 absolute animate-spin" />
    </div>
  );
};
export default ApplicationLoader;
