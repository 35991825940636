import React, { useEffect, useRef, useState } from "react";
import SecondaryButton from "../../../Shared/Buttons/SecondaryButton/SecondaryButton";
import SelectedDrivers from "../SelectedDrivers/SelectedDrivers";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../redux/store";
import { localIpDevServer, swappingRoute } from "../../../../App";
import {
  freeTrialSelectedPrizeNumber,
  setPremiumSwapImage,
} from "../../../../redux/reducers/imageReducer";
import { asyncAddRemoteResourcesPayload } from "../../../../redux/actions/freeTrialActions";

interface DriversCartProps {
  maxDrivers: number;
  selectedDrivers: any;
  buyButton?: React.MouseEventHandler<HTMLButtonElement>;
}

const DriversCart: React.FC<DriversCartProps> = ({
  maxDrivers,
  selectedDrivers,
  buyButton,
}) => {
  const [isCartEmpty, setIsCartEmpty] = useState<boolean>(true);
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch();
  const selectedCharacters = useSelector(
    (rootState: RootState) => rootState.characters.selectedCharacters.length
  );
  const openCamera = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const image = reader.result;
        console.log("Image URL:", image);
        const endpoint = `${localIpDevServer}${swappingRoute}`;
        const base64Image: string = image as string;
        //console.log("base64Image", base64Image);
        dispatch(setPremiumSwapImage(base64Image));
      };
      reader.readAsDataURL(file);
      navigate("/checkout");
    }
  };

  const handleClick = () => {
    openCamera();
    //navigate("/checkout");
  };

  useEffect(() => {
    if (selectedCharacters > 0) {
      setIsCartEmpty(false);
    } else {
      setIsCartEmpty(true);
    }
  }, [selectedCharacters]);

  return (
    <div className="w-full h-1/2 bg-gradient-to-b from-[#E10600] to-black pl-6 pb-6 font-[Titillium Web]">
      {/*bg-gradient-to-b from-[#E10600] to-black */}
      <div className="flex justify-between pt-3.5">
        <div className="text-white w-1/2 flex items-end m-[-1%] ">
          Choose 1 to 30 drivers
        </div>
        <div className="h-full flex justify-end w-1/2 pr-3.5">
          <div className="flex items-center justify-center mt-[6px]">
            <input
              type="file"
              ref={fileInputRef}
              accept="image/*"
              capture="user"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </div>
          <SecondaryButton
            enabled={!isCartEmpty}
            text={"SNAP A PIC!"}
            fontSize="xs"
            width="32"
            height="7"
            onClick={handleClick}
          />
        </div>
      </div>
      <div className="pt-2.5">
        <SelectedDrivers
          maxDrivers={maxDrivers}
          selectedDrivers={selectedDrivers}
        />
      </div>
    </div>
  );
};

export default DriversCart;
