import React from "react";

import ArrowIcon from "../../assets/SVG/arrowIcons.svg";
import { twMerge } from "tailwind-merge";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface TeamsCardProps {
  name: string;
  bgColor: string;
  onClick?: (team: string) => void;
  bgImage: string;
  className?: string;
}

const TeamsCard: React.FC<TeamsCardProps> = ({
  name,
  bgColor,
  onClick,
  bgImage,
  className = [],
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick(name);
    }
  };
  const characterArrays = useSelector(
    (rootState: RootState) => rootState.characters.selectedCharacters
  );
  const isSelected = (name: string) => {
    if (characterArrays.includes(name)) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div
      className={twMerge(
        "relative flex flex-col items-start w-327 h-112 gap-2 border-4  rounded-md",
        isSelected(name) ? "selectedCardBorder" : "border-white"
      )}
      onClick={handleClick}
    >
      <div
        className={`absolute inset-0`}
        style={{
          background: isSelected(name)
            ? "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #51E18E 100%)"
            : `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, ${bgColor} 100%)`,
        }}
      ></div>
      <img src={bgImage} alt="" className="w-full h-full object-cover" />
      <div className="absolute inset-0 flex flex-row items-end justify-start p-4">
        <div className="font-Formular1R text-white text-xl uppercase flex max-w-40 items-center">
          {name}{" "}
          <img
            src={ArrowIcon}
            alt=""
            className="h-6 w-6 ml-2 mt-2"
            style={{ lineHeight: "1.5rem" }}
          />
        </div>
      </div>
    </div>
  );
};
export default TeamsCard;
