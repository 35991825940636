import { createAction } from "@reduxjs/toolkit";
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';  // Import Axios
import { localIpDevServer, swappingRoute } from "../../App";

export interface asyncAddRemoteResourcesPayload {
    base64UserImage: string | ArrayBuffer | null
    selectedTeam: string | undefined
}

export const addRemoteResources = createAction<string[] | undefined>('freeTrial/addRemoteResources')


export const asyncAddRemoteResources = createAsyncThunk('freeTrial/asyncAddRemoteResources', async (data: asyncAddRemoteResourcesPayload, thunkAPI) => {
    try {
        // Perform an asynchronous POST operation using Axios
        console.log("data", data)
        const response = await axios
            .post(`${localIpDevServer}${swappingRoute}`, {
                base64: data.base64UserImage,
                selectedTeam: data.selectedTeam,
            })
        // Return the response data to be used in the success action
        return response.data.remoteResources;

    } catch (error) {
        // Handle errors and return an object for the error action
        return thunkAPI.rejectWithValue({ error: error });
    }
});
