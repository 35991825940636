import React from 'react';
import { Link } from 'react-router-dom';
import arrowBack from '../../../assets/SVG/arrowBack.svg';

const BackBar = () => {
  return (
    <div className="flex w-full p-4 bg-red-600">
      <Link to="/home">
        <img src={arrowBack} alt="Arrow Back" width={30} />
      </Link>
    </div>
  );
};

export default BackBar;
