import React from "react";

interface SecondaryButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  enabled: boolean;
  text: string;
  hexTextColor?: string;
  fontSize?: string;
  width?: string;
  height?: string;
}

const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  onClick,
  enabled,
  text,
  hexTextColor,
  width,
  height,
  fontSize,
}) => {
  return (
    <button
      className={`w-${width ? width : "9/12"} h-${
        height ? height : "10"
      } rounded-full uppercase text-12 ${
        enabled ? "bg-white" : "bg-[#D9D9D9] cursor-not-allowed"
      }`}
      disabled={!enabled}
      onClick={onClick}
    >
      <div
        className={`font-bold text-[${
          hexTextColor ? hexTextColor : "#E10700"
        }] text-${fontSize ? fontSize : "base"}`}
      >
        {text}
      </div>
    </button>
  );
};

export default SecondaryButton;
